import { observer } from "mobx-react";
import URI from "urijs";

import StandardVideoBlock from "../../StandardTheme/VideoBlock";
import {StoreContext} from "../../../../stores/StoreLoader";
import {useContext, useRef} from "react";
import {CSSTransition} from "react-transition-group";
import styles from "./VideoBlock.module.scss";
import ClickableLink from "../../../utilities/ClickableLink";
import {IBlockComponentProps} from "../_Block";
import {NormalizedVideoVendor} from "../BlockObjects/VideoBlock/NormalizedVideo";
import ErrorBlock from "../ErrorBlock";

const titleTransitionClassNames = {
    enter: styles.transitionEnter,
    appear: styles.transitionAppear,
    enterActive: styles.transitionEnterActive,
    appearActive: styles.transitionAppearActive,
    enterDone: styles.transitionEnterDone,
    appearDone: styles.transitionAppearDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

interface VimeoVideoBlockObj extends Omit<IVideoBlockObj, "link" | "modalId" | "video"> {
    link: string,
    modalId: number,
    video?: IVideoObject,
}

interface VimeoVideoBlockProps {
    blockObj: VimeoVideoBlockObj,
    blockHovered: boolean,
}

const VimeoVideo = observer((props: VimeoVideoBlockProps) => {
    const {i18nStore} = useContext(StoreContext);
    const langCode = i18nStore.googleCode;
    const nodeRef = useRef(null);

    let embedLink = props.blockObj.video?.url || "";
    if (embedLink) {
        embedLink = URI(embedLink)
            .addSearch("texttrack", langCode)
            .toString();

        embedLink = embedLink.replace('/vimeo.com', '/player.vimeo.com/video');
    }

    return <ClickableLink className={styles.blockLink} href={props.blockObj.link}>
        <div className="sb-blockMedia">
            {props.blockObj.modalId !== null && <div
                className={`embed-responsive ${props.blockObj.sizeX === 1 ? 'embed-responsive-4by3' : 'embed-responsive-16by9'}`}
                style={{"minHeight": "133px"}}>
                <iframe className="embed-responsive-item" src={embedLink} allowFullScreen/>
            </div>}
        </div>
        <CSSTransition in={props.blockHovered} timeout={800} classNames={titleTransitionClassNames} nodeRef={nodeRef}>
            <div className="sb-blockVideoDesc " ref={nodeRef}>
                <h4 className="sb-block-videoName sb-organization-color-element-bg text-center">VIMEO</h4>
                <br/>
                <span className="sb-video-title-truncate" data-video-title={props.blockObj.video?.title}>{props.blockObj.title}</span>
            </div>
        </CSSTransition>
    </ClickableLink>
});

interface SchoolTubeVideoBlockObj extends Omit<IVideoBlockObj, "link" | "title" | "video"> {
    link: string,
    title: string,
    video: IVideoObject,
}

interface SchoolTubeVideoBlockProps {
    blockObj: SchoolTubeVideoBlockObj,
    blockHovered: boolean,
}

const SchooltubeVideo = observer((props: SchoolTubeVideoBlockProps) => {
    const {i18nStore} = useContext(StoreContext);
    const nodeRef = useRef(null);
    let id = '';
    const langCode = i18nStore.googleCode;
    const embedLink = props.blockObj.link;
    if (embedLink) {
        const uri = new URI(embedLink);
        id = uri.segment(-1);
    }

    return <ClickableLink className={styles.blockLink} href={embedLink}>
        <div className="sb-blockMedia">
            {embedLink && <div
                className={`embed-responsive ${props.blockObj.sizeX === 1 ? 'embed-responsive-4by3' : 'embed-responsive-16by9'}`}
                style={{"minHeight": "133px"}}>
                <iframe id="kaltura_player"
                        className="embed-responsive-item"
                        src={`https://cdnapisec.kaltura.com/p/2531481/sp/253148100/embedIframeJs/uiconf_id/44585062/partner_id/2531481?iframeembed=true&playerId=kaltura_player&entry_id=${id}&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=${langCode}&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_thjypdcp`}
                        width="640" height="360" allowFullScreen
                        allow="autoplay *; fullscreen *; encrypted-media *"
                        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
                        frameBorder="0" title="Kaltura Player"/>
            </div>}
        </div>
        <CSSTransition in={props.blockHovered} timeout={800} classNames={titleTransitionClassNames} nodeRef={nodeRef}>
            <div className="sb-blockVideoDesc " ref={nodeRef}>
                <h4 className="sb-block-videoName sb-organization-color-element-bg text-center">SCHOOLTUBE</h4>
                <br/>
                <span id={`sb-block-videoTitle-${props.blockObj.id}`}
                      className="sb-video-title-truncate sb-block-videoTitle"
                      data-video-title={props.blockObj.video.title}>{props.blockObj.title}</span>
            </div>
        </CSSTransition>
    </ClickableLink>
});

interface NormalizedVideoBlockObj extends Omit<IVideoBlockObj, "link" | "video" | "title"> {
    link: string,
    title: string,
    video: IVideoObject,
}

interface NormalizedVideoBlockProps {
    blockObj: NormalizedVideoBlockObj,
    blockHovered: boolean,
}

function NormalizedVideo(props: NormalizedVideoBlockProps) {
    const nodeRef = useRef(null);

    return <ClickableLink className={styles.blockLink} href={props.blockObj.video.viewLink}>
        <div className="sb-blockMedia">
            <div
                className={`embed-responsive ${props.blockObj.sizeX === 1 ? 'embed-responsive-4by3' : 'embed-responsive-16by9'}`}
                style={{"minHeight": "133px"}}>
                <iframe className="embed-responsive-item" src={props.blockObj.video.embedLink} allowFullScreen
                        style={{"height": "100%"}}/>
            </div>
        </div>
        <CSSTransition in={props.blockHovered} timeout={800} classNames={titleTransitionClassNames} nodeRef={nodeRef}>
            <div className="sb-blockVideoDesc " ref={nodeRef}>
                <h4 className="sb-block-videoName sb-organization-color-element-bg text-center">{props.blockObj.video.vendor}</h4>
                <br/>
                <span className="sb-video-title-truncate"
                      data-video-title={props.blockObj.video.title}>{props.blockObj.title}</span>
            </div>
        </CSSTransition>
    </ClickableLink>
}

interface MyvrsVideoBlockObj extends Omit<IVideoBlockObj, "url"> {
    url: string,
}

interface MyvrsVideoBlockProps {
    blockObj: MyvrsVideoBlockObj,
    blockHovered: boolean,
}

function MyvrspotVideo(props: MyvrsVideoBlockProps) {
    const nodeRef = useRef(null);

    return <ClickableLink className={styles.blockLink} href={props.blockObj.url}>
        <div className="sb-blockMedia">
            <div
                className={`embed-responsive ${props.blockObj.sizeX === 1 ? 'embed-responsive-4by3' : 'embed-responsive-16by9'}`}
                style={{"minHeight": "133px"}}>
                <iframe src={props.blockObj.url} allowFullScreen/>
            </div>
        </div>
        <CSSTransition in={props.blockHovered} timeout={800} classNames={titleTransitionClassNames} nodeRef={nodeRef}>
            <div className="sb-blockVideoDesc " ref={nodeRef}>
                <h4 className="sb-block-videoName text-center sb-organization-color-element-bg">MyVRSpot</h4>
                {props.blockObj?.title && <><br/>
                    <span className="sb-video-title-truncate"
                          data-video-title={props.blockObj.title}>{props.blockObj.title}</span></>}
            </div>
        </CSSTransition>
    </ClickableLink>
}

interface GoogleDriveVideoBlockObj extends Omit<IVideoBlockObj, "title" | "url"> {
    data: {
        file: string,
    },
    title: string,
    url: string,
}

interface GoogleDriveVideoBlockProps {
    blockObj: GoogleDriveVideoBlockObj,
    blockHovered: boolean,
}

function GoogledriveVideo(props: GoogleDriveVideoBlockProps) {
    const nodeRef = useRef(null);

    return <ClickableLink className={styles.blockLink} href={props.blockObj.url}>
        <div className="sb-blockMedia">
            <div
                className={`embed-responsive ${props.blockObj.sizeX === 1 ? 'embed-responsive-4by3' : 'embed-responsive-16by9'}`}
                style={{"minHeight": "133px"}}>
                <iframe className="embed-responsive-item"
                        src={`https://drive.google.com/file/d/${props.blockObj.data.file}/preview`} allowFullScreen/>
            </div>
        </div>
        <CSSTransition in={props.blockHovered} timeout={800} classNames={titleTransitionClassNames} nodeRef={nodeRef}>
            <div className="sb-blockVideoDesc " ref={nodeRef}>
                <h4 className="sb-block-videoName text-center sb-organization-color-element-bg">Google Video</h4>
                {props.blockObj.title && <>
                    <br/>
                    <span className="sb-video-title-truncate"
                          data-video-title={props.blockObj.title}>{props.blockObj.title}</span>
                </>}
            </div>
        </CSSTransition>
    </ClickableLink>
}

interface IVideoBlockProps extends IBlockComponentProps {
    blockObj: IVideoBlockObj
}

const VideoBlock = (props: IVideoBlockProps) => {
    const blockObj = props.blockObj;

    switch (blockObj.videoType) {
        case NormalizedVideoVendor.DRIVEGOOGLE:
            return <GoogledriveVideo blockObj={blockObj as GoogleDriveVideoBlockObj} blockHovered={props.blockHovered}/>;
        case NormalizedVideoVendor.MYVRSPOT:
            return <MyvrspotVideo blockObj={blockObj as MyvrsVideoBlockObj} blockHovered={props.blockHovered}/>;
        case NormalizedVideoVendor.SCHOOLTUBE:
            return <SchooltubeVideo blockObj={blockObj as SchoolTubeVideoBlockObj} blockHovered={props.blockHovered}/>;
        case NormalizedVideoVendor.VIMEO:
            return <VimeoVideo blockObj={blockObj as VimeoVideoBlockObj} blockHovered={props.blockHovered}/>;
        case 'youtube':
            return <StandardVideoBlock blockObj={blockObj} isTitle={blockObj.isTitle} sizeX={props.sizeX} sizeY={props.sizeY} embedResponsive={blockObj.embedResponsive}/>;
        default:
            return <ErrorBlock blockObj={{errorMessage: "Video type is not supported"}}/>
    }
};

export default VideoBlock;
