import { Component } from "react";
import PropTypes from "prop-types";
import ImageComponent from "./ImageComponent";

export default class PlaceHolderImage extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        imageSource: PropTypes.string.isRequired,
    };

    render() {
        const { type, imageSource } = this.props;

        return <ImageComponent src={imageSource} alt={type + " Placeholder"} />;
    }
}
